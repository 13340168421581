import React from 'react'
import styles from './TableSection.module.css'

const TableHeader = ({ data, width, add, color }) => {
    return (
        <div className={styles.container}>
            <div style={{
                width: width ? width : null, background: color ? color : null,
            }} className={styles.headersec}>
                {data}
            </div>
        </div>
    )
}

export default TableHeader
