import React, { useEffect, useState } from 'react'
import styles from './Table/Table.module.css';
import TableHeader from '../../Utills/Table/TableHeader';
import axios from 'axios';
import dayjs from 'dayjs';

const AddTable = ({ orders }) => {
    const [codes, setCodes] = useState([]);
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [formData, setFormData] = useState({
        name: '',
        clothes: [{ code: '', unit: 0, price: 0, total: 0, search: '', img: '', handle: '', day: 0, sizes: [] }],
        phone: '',
        address: '',
        orderRecieve: '',
        orderDay: '',
        whoCreated: '',
        whoCountet: '',
        bankAccountPay: 0,
        cash: 0,
        card: 0,
        bankDescription: '',
        prePayment: 0,
        rent: 0,
        collateral: 0,
        recieveClothes: '',
        safe: false,
        status: '',
        bankAccount: '',
        isBack: '',
        createdAt: dayjs(),
        orderId: dayjs().format('MMDD') +
            (parseInt(orders.filter((e) => dayjs(e.createdAt).isSame(dayjs(), 'date')).length) + 1)
    });

    useEffect(() => {
        const url = 'https://clothes.templateapi.xyz';
        if (isLoading) {
            const fetchPosts = axios.get(url + '/post');
            const fetchUsers = axios.get(url + '/user');

            Promise.all([fetchPosts, fetchUsers])
                .then(([postsResponse, usersResponse]) => {
                    setCodes(postsResponse.data);
                    setUsers(usersResponse.data.data);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                });
        }
    }, [isLoading]);


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleClothesChange = (index, e) => {
        const { name, value } = e.target;
        const clothes = [...formData.clothes];
        clothes[index][name] = value;
        if (name === 'code') {
            const selectedCode = codes.find(code => code.title === value);
            if (selectedCode) {
                clothes[index].price = selectedCode.price;
                clothes[index].img = selectedCode.cover;
                clothes[index].sizes = selectedCode.sizes || [];
                clothes[index].handle = selectedCode.isHandle.toString();
            }
        }

        clothes[index].total = clothes[index].unit * clothes[index].price * clothes[index].day;
        setFormData(prevState => ({
            ...prevState,
            clothes
        }));
        calculateTotalRent();
    };

    const calculateCollateral = (rent) => {
        let collateralPercentage;
        if (rent >= 0 && rent < 100000) {
            collateralPercentage = 100;
        } else if (rent >= 100000 && rent < 300000) {
            collateralPercentage = 50;
        } else if (rent >= 300000 && rent < 500000) {
            collateralPercentage = 40;
        } else if (rent >= 500000 && rent < 2000000) {
            collateralPercentage = 30;
        } else if (rent >= 2000000) {
            collateralPercentage = 20;
        }

        return (rent * collateralPercentage) / 100;
    };

    const calculateTotalRent = () => {

        const totalRent = formData.clothes.reduce((sum, item) => sum + item.total, 0);
        const newCollateral = calculateCollateral(totalRent);

        setFormData(prevState => ({
            ...prevState,
            rent: totalRent,
            collateral: newCollateral
        }));
    };

    const addClothingItem = () => {
        setFormData(prevState => ({
            ...prevState,
            clothes: [...prevState.clothes, { code: '', unit: 0, price: 0, total: 0, search: '', sizes: [], img: '', day: 0, handle: '', }]
        }));
    };

    const removeClothingItem = (index) => {
        const clothes = [...formData.clothes];
        clothes.splice(index, 1);
        setFormData(prevState => ({
            ...prevState,
            clothes
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('https://clothes.templateapi.xyz/orders', formData)
            .then(() => {
                alert('Амжилттай хадгалагдлаа');
                window.location.reload(false);
            })
            .catch((e) => console.log(e));
        // console.log(formData);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    };

    return (
        <div >
            <div className={styles.container}>
                {isLoading ?
                    <div className="loader-container"><div className="loader"></div></div> :
                    <div className={styles.container}>
                        <div className='row' style={{ alignItems: 'start', justifyContent: 'start' }}>
                            <div className='column'>
                                <TableHeader color={'#00FFEF'} data={
                                    dayjs().format('YYMMDD') +
                                    (parseInt(orders.filter((e) => dayjs(e.createdAt).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')).length) + 1)
                                } />
                                <TableHeader color={'#00FFEF'} data={'Нэр'} />
                                <TableHeader color={'#00FFEF'} data={'Утасны дугаар'} />
                                <TableHeader color={'#00FFEF'} data={'Хаяг'} />
                            </div>
                            <div className='column'>
                                <TableHeader color={'#00FFEF'} width={window.innerWidth > 720 ? '7vw' : '30vw'} data={''} />
                                <div className={styles.sec}>
                                    <input type="text" placeholder='name' name={'name'} onChange={handleChange} id="" />
                                </div>

                                <div className={styles.sec}>
                                    <input type="text" name={'phone'} placeholder='phone' onChange={handleChange} id="" />
                                </div>

                                <div className={styles.sec}>
                                    <textarea type="text" name={'address'} placeholder='address'
                                        onChange={handleChange} cols="30" rows="50" style={{ height: '50px' }} ></textarea>
                                    {/* <input id="" /> */}
                                </div>
                            </div>
                            <div className='column'>
                                {/* <TableHeader data={'Тоо'} /> */}
                                {formData.clothes.map((item, index) => (
                                    <div key={index} className='row'>
                                        <div className="column">
                                            <TableHeader color={'#00FFEF'} data={'Код хайх'} width={window.innerWidth > 720 ? '4vw' : '20vw'} />
                                            <div className={styles.sec} style={{ width: window.innerWidth > 720 ? '5vw' : '20vw' }}>
                                                <input type="text" placeholder="Search code" value={formData.clothes[index].search} name='search'
                                                    onChange={(e) => handleClothesChange(index, e)} style={{ width: window.innerWidth > 720 ? '4vw' : '20vw' }} />
                                            </div>
                                        </div>
                                        <div className='column'>
                                            <TableHeader color={'#00FFEF'} data={'Код'} />
                                            <div className={styles.sec} style={{ width: window.innerWidth > 720 ? '4vw' : '20vw' }}>
                                                <select name="code" value={item.code}
                                                    onChange={(e) => handleClothesChange(index, e)}>
                                                    <option value="">Бүгд</option>
                                                    {codes.filter(code => code.title === formData.clothes[index].search).map((code) => (
                                                        <option key={code.title} value={code.title}>{code.title}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='column'>
                                            <TableHeader color={'#00FFEF'} data={'Хэмжээ'} />
                                            <div className={styles.sec} style={{ width: window.innerWidth > 720 ? '4vw' : '20vw' }}>
                                                <select name="code" value={item.code} onChange={(e) => handleClothesChange(index, e)}>
                                                    <option value="">Бүгд</option>
                                                    {formData.clothes.map((e) => (
                                                        e.sizes
                                                            .map((element) =>
                                                                <option key={element.size} value={element.size}>{element.size}</option>
                                                            )
                                                    ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className='column'>
                                            <TableHeader color={'#00FFEF'} width={window.innerWidth > 720 ? '3vw' : '22vw'} data={'Тоо'} />
                                            <div className={styles.sec} style={{ width: window.innerWidth > 720 ? '4vw' : '25vw' }}>
                                                <input type="number" name="unit" style={{ width: window.innerWidth > 720 ? '3.5vw' : '25vw' }}
                                                    value={item.unit} onChange={(e) => handleClothesChange(index, e)} />
                                            </div>
                                        </div>
                                        <div className='column'>
                                            <TableHeader color={'#00FFEF'} width={window.innerWidth > 720 ? '3vw' : '22vw'} data={'Өдөр'} />
                                            <div className={styles.sec} style={{ width: window.innerWidth > 720 ? '4vw' : '25vw' }}>
                                                <input type="number" name="day" style={{ width: window.innerWidth > 720 ? '3.5vw' : '25vw' }}
                                                    value={item.day} onChange={(e) => handleClothesChange(index, e)} />
                                            </div>
                                        </div>
                                        <div className='column'>
                                            <TableHeader color={'#00FFEF'} width={window.innerWidth > 720 ? '3vw' : '22vw'} data={'Нэгж хувцасны үнэ'} />
                                            <div className={styles.sec} style={{ width: window.innerWidth > 720 ? '4vw' : '25vw' }}>
                                                <input type="number" name="" value={item.price} style={{ width: window.innerWidth > 720 ? '3.5vw' : '25vw' }}
                                                    onChange={(e) => handleClothesChange(index, e)} readOnly />
                                            </div>
                                        </div>
                                        <div className='column'>
                                            <TableHeader color={'#00FFEF'} width={window.innerWidth > 720 ? '4vw' : '25vw'} data={'Нийт үнэ'} />
                                            <div className={styles.sec} style={{ width: window.innerWidth > 720 ? '5vw' : '25vw' }}>
                                                <input type="number" name="price" value={item.total} readOnly style={{ width: window.innerWidth > 720 ? '4.5vw' : '25vw' }} />
                                            </div>
                                        </div>
                                        {/* <input type="text" onChange={handleClothesChange(index, e)} name="img" id="" /> */}
                                    </div>
                                ))}
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px', paddingTop: '15px' }}>
                                    <button type="button" onClick={addClothingItem}>Хувцас нэмэх</button>
                                    <button style={{ color: 'red', borderColor: 'red' }}
                                        type="button" onClick={() => removeClothingItem(formData.clothes.length - 1)}>Хувцас хасах</button>
                                </div>
                            </div>
                            <div className='column'>
                                <TableHeader color={'#00FFEF'} data={' '} />
                                <TableHeader color={'#00FFEF'} data={'Захиалга авсан хүн'} />
                                <TableHeader color={'#00FFEF'} data={'Захиалга авах өдөр'} />
                                <TableHeader color={'#00FFEF'} data={'Бэлдсэн хүн'} />
                                <TableHeader color={'#00FFEF'} data={'Тоолж өгсөн хүн'} />
                            </div>
                            <div className='column'>
                                <TableHeader color={'#00FFEF'} width={window.innerWidth > 720 ? '7vw' : '30vw'} data={' '} />

                                <div className={styles.sec}>
                                    <select name="orderRecieve" onChange={handleChange}>
                                        <option value="">Сонгох</option>
                                        {users.map((e) => (
                                            <option value={e.name}>{e.name}</option>
                                        ))}
                                    </select>
                                    {/* <input type="text" name={'orderRecieve'} placeholder='orderRecieve' onChange={handleChange} id="" /> */}
                                </div>

                                <div className={styles.sec}>
                                    <input type="text" name={'orderDay'} placeholder='orderDay'
                                        onChange={handleChange} id="" />
                                </div>

                                <div className={styles.sec}>
                                    <input type="text" name={'whoCreated'} placeholder='whoCreated' onChange={handleChange} id="" />
                                </div>

                                <div className={styles.sec}>
                                    <input type="text" name={'whoCountet'} placeholder='whoCountet' onChange={handleChange} id="" />
                                </div>
                            </div>
                            <div className='column'>
                                <TableHeader color={'#00FFEF'} data={' '} />
                                <TableHeader color={'#00FFEF'} data={'Данс'} />
                                <TableHeader color={'#00FFEF'} data={'Бэлэн'} />
                                <TableHeader color={'#00FFEF'} data={'Карт'} />
                                <TableHeader color={'#00FFEF'} data={'Нэмэлт тайлбар'} />
                            </div>
                            <div className='column'>
                                <TableHeader color={'#00FFEF'} width={window.innerWidth > 720 ? '7vw' : '30vw'} data={' '} />

                                <div className={styles.sec}>
                                    <input type="text" name={'bankAccountPay'} placeholder='bankAccountPay' onChange={handleChange} id="" />
                                </div>

                                <div className={styles.sec}>
                                    <input type="text" name={'cash'} placeholder='cash' onChange={handleChange} id="" />
                                </div>

                                <div className={styles.sec}>
                                    <input type="text" name={'card'} placeholder='card' onChange={handleChange} id="" />
                                </div>

                                <div className={styles.sec}>
                                    {/* <input type="text" name={'bankDescription'} placeholder='bankDescription' onChange={handleChange} id="" /> */}

                                    <textarea type="text" name={'bankDescription'} placeholder='bankDescription'
                                        onChange={handleChange} cols="30" rows="30" style={{ height: '50px' }} ></textarea>
                                </div>
                            </div>
                            <div className='column'>
                                <TableHeader color={'#00FFEF'} data={' '} />
                                <TableHeader color={'#00FFEF'} data={'Барьцаа авсан хүн'} />
                                <TableHeader color={'#00FFEF'} data={'Түрээс'} />
                                <TableHeader color={'#00FFEF'} data={'Барьцаа'} />
                                <TableHeader color={'#00FFEF'} data={'Төлөв'} />
                            </div>
                            <div className='column'>
                                <TableHeader color={'#00FFEF'} width={window.innerWidth > 720 ? '7vw' : '30vw'} data={' '} />

                                <div className={styles.sec}>
                                    <input type="text" onKeyPress={handleKeyPress} name={'prePayment'} placeholder='prePayment' onChange={handleChange} id="" />
                                </div>

                                <div className={styles.sec}>
                                    <input type="number" onKeyPress={handleKeyPress} name={'rent'} value={formData.rent} placeholder='rent' onChange={handleChange} id="" />
                                </div>

                                <div className={styles.sec}>
                                    <input type="number" onKeyPress={handleKeyPress} value={formData.collateral} name={'collateral'} placeholder='collateral' onChange={handleChange} id="" />
                                </div>

                                <div className={styles.sec}>
                                    <select onKeyPress={handleKeyPress} name="status" onChange={handleChange}>
                                        <option value="">Сонгох</option>
                                        <option value="Захиалга">Захиалга</option>
                                        <option value="Дууссан захиалга">Дууссан захиалга</option>
                                        <option value="Дутуу авчирсан">Дутуу авчирсан</option>
                                        <option value="Авч явж байгаа">Авч явж байгаа</option>
                                        <option value="Зарсан">Зарсан</option>
                                    </select>
                                    {/* <input type="text" name={'status'} placeholder='status' onChange={handleChange} id="" /> */}
                                </div>
                            </div>
                            <div className='column'>
                                <TableHeader color={'#00FFEF'} data={' '} />
                                <TableHeader color={'#00FFEF'} data={'Хувцасны бүрэн ирсэн'} />
                                <TableHeader color={'#00FFEF'} data={'Хувцас хүлээж авсан хүн'} />
                                <TableHeader color={'#00FFEF'} data={'Дансны дугаар'} />
                                <TableHeader color={'#00FFEF'} data={'Буццалт хийгдсэн'} />
                            </div>
                            <div className='column'>
                                <TableHeader color={'#00FFEF'} width={window.innerWidth > 720 ? '7vw' : '30vw'} data={' '} />

                                <div className={styles.sec}>

                                    {/* <select name="safe" onChange={handleChange}>
                                    <option value="">Сонгох</option>
                                    <option value={false}></option>
                                    <option value={true}></option>
                                </select> */}
                                    {/* <input type="text" name={'safe'} placeholder='safe' onChange={handleChange} id="" /> */}
                                </div>

                                <div className={styles.sec}>
                                    <input type="text" name={'recieveClothes'} placeholder='recieveClothes' onChange={handleChange} id="" />
                                </div>

                                <div className={styles.sec}>
                                    <input type="text" name={'bankAccount'} placeholder='bankAccount' onChange={handleChange} id="" />
                                </div>

                                <div className={styles.sec}>
                                    {/* <input type="text" name={'isBack'} placeholder='isBack' onChange={handleChange} id="" /> */}
                                </div>
                            </div>
                        </div>
                        <br />
                        <div style={{
                            display: 'flex', width: '90vw',
                            justifyContent: 'end', alignItems: 'end',
                        }}>
                            <button style={{
                                marginLeft: '30px',
                            }} onClick={handleSubmit}>Хадгалах</button>
                        </div>
                    </div>}
                <div>
                    <div className="row" style={{ gap: '20px' }}>
                        {formData.clothes.length > 0 ? formData.clothes.map((e, index) => (
                            <div key={index}>
                                {console.log(e)}
                                <h2>{e.code.toString()}</h2>
                                <div>
                                    {e.handle ?
                                        <img src={'https://clothes.templateapi.xyz/uploads/' + e.code + '.jpg'}
                                            style={{ width: '150px', objectFit: 'cover' }}
                                        />
                                        :
                                        <img src={'https://clothes.templateapi.xyz/' + e.img}
                                            style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                        />
                                    }
                                </div>
                                <div>
                                    {e.sizes ? e.sizes.map((el) => (
                                        <div style={{ display: 'flex', gap: '20px', padding: '5px' }}>
                                            <p>Хэмжээ: {el.size}</p>
                                            <p>Үлдэгдэл: {el.balance}</p>
                                        </div>
                                    )) : null}
                                </div>
                            </div>
                        )) : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddTable
