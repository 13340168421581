import React, { useState } from 'react'
import styles from './Home.module.css';
import Table from '../../Component/Home/Table/Table';
import AddTable from '../../Component/Home/AddTable';
import Modal from '../../Utills/Modal/Modal';
import dayjs from 'dayjs';
import EditTable from '../../Component/Home/EditTable';
import BackList from '../../Utills/Modal/BackList/BackList';

const Home = ({ orders }) => {
    const [modal, setModal] = useState(false);
    const [isBack, setIsBack] = useState(false);
    const [successBack, setSuccessBack] = useState(false);
    const [order, setOrder] = useState(false);
    const [back, setBack] = useState(false);
    const [phone, setPhone] = useState('');
    const [orderId, setOrderId] = useState('');
    const [editingOrderId, setEditingOrderId] = useState(null); // New state to keep track of the order being edited
    const [backModal, setBackModal] = useState(false);
    const [remove, setIsRemove] = useState(false);

    const closeModal = () => {
        setModal(false);
        setBackModal(false);
    }

    return (
        <>
            {modal ? <Modal closeModal={closeModal} /> : null}
            {backModal ? <BackList orders={orders} closeModal={closeModal} /> : null}
            <div className={styles.container}>
                {window.innerWidth < 720 ? <button onClick={() => setBackModal(true)}>Барьцаа буцаан олгох жагсаалт</button> : <></>}
                <div className={window.innerWidth > 720 ? 'row' : 'column'} style={{ gap: '10px' }}>
                    <label className="label">
                        Дугаараар хайх:
                        <input onChange={(e) => setPhone(e.target.value)} placeholder='Дугаараар хайх'
                            style={{ width: '20vw' }} type="text" name="" id="" />
                    </label>
                    <label className="label">
                        Бүртгэлийн кодоор хайх:
                        <input onChange={(e) => setOrderId(e.target.value)} placeholder='Бүртгэлийн кодоор хайх'
                            style={{ width: '20vw' }} type="text" name="" id="" />
                    </label>
                    <button onClick={() => setModal(true)}>Хувцасны үлдэгдэл харах</button>
                    <button onClick={() => {
                        setIsBack(false);
                        setSuccessBack(false);
                        setOrder(false);
                    }} style={{ backgroundColor: isBack ? 'cyan' : '' }}>
                        Нийт бүртгэл: {orders.length}
                    </button>
                    <button onClick={() => {
                        setSuccessBack(true);
                        setIsBack(false);
                        setOrder(false);
                    }} style={{ backgroundColor: successBack ? 'cyan' : '' }}>
                        Бүгд дууссан: {orders.filter((e) => e.status === 'Дууссан захиалга').length}
                    </button>
                    <button onClick={() => {
                        setIsBack(false);
                        setSuccessBack(false);
                        setOrder(true);
                    }} style={{ backgroundColor: order ? 'cyan' : '' }}>
                        Урьдчилсан захиалга харах
                    </button>
                </div>
                <div className={window.innerWidth > 720 ? 'row' : 'column'} style={{ gap: '10px' }}>

                    <button onClick={() => {
                        setIsRemove(true)
                    }} style={{ backgroundColor: remove ? 'cyan' : '', alignItems: 'end', justifyContent: 'end' }}>
                        Дутуу авчирсан, Зарсан, Авчраагүй
                    </button>
                    {/* <button onClick={() => {
                        setIsBack(false);
                        setSuccessBack(false);
                        setOrder(true);
                    }} style={{ backgroundColor: order ? 'cyan' : '' }}>
                        Зарсан
                    </button>
                    <button onClick={() => {
                        setIsBack(false);
                        setSuccessBack(false);
                        setOrder(true);
                    }} style={{ backgroundColor: order ? 'cyan' : '' }}>
                        Авчраагүй
                    </button> */}
                </div>
                <AddTable orders={orders} />
                {orders.filter((e) => isBack ? e.isback === false : e)
                    .filter((e) => successBack ? e.status === 'Дууссан захиалга' : e)
                    .filter((e) => order ? e.status === 'Захиалга' : e)
                    .filter((e) => phone ? e.phone.toString().includes(phone) : e)
                    .filter((e) => remove ? e.status ===
                        'Дутуу авчирсан'
                        || e.status === 'Зарсан' ||
                        e.status === 'Авч явж байгаа'
                        : e)
                    .filter((e, index) => orderId ? `${dayjs(e.createdAt).format('MM-DD')} / ${index + 1}`.includes(orderId) : e)
                    .map((e, index) => (
                        <div key={e.id}>
                            {/* <button onClick={() => setEditingOrderId(e.id)}>Захиалга өөрчлөх</button> */}
                            {editingOrderId === e.id ? (
                                <EditTable orders={orders} order={e} />
                            ) : (
                                <Table e={e} index={index + 1} setId={setEditingOrderId} />
                            )}
                        </div>
                    ))}
            </div>
        </>
    )
}

export default Home