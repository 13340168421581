import React from 'react'
import styles from './Modal.module.css'

const Modal = ({ closeModal }) => {
    return (
        <div className='blur_container'>
            <div className="modal">
                <div className="two-line">
                    <h2>Хувцас хайх</h2>
                    <h2 onClick={closeModal} style={{ cursor: 'pointer' }}>X</h2>
                </div>
                <label htmlFor="" className='label'>
                    <p>Хувцасны код:</p>
                    <input placeholder='Хувцасны код' type="text" style={{ width: '35vw' }} />
                </label>
                <div className="row" style={{ gap: '20px' }}>
                    <img src="https://clothes.templateapi.xyz/uploads/9-26.jpg" alt="" style={{ height: '120px', width: '120px' }} />
                    <div className="column" style={{ gap: '20px', alignItems: 'start' }}>
                        <p>Нийт: 2</p>
                        <p>Захиалга хийгдсэн: 2</p>
                        <p>Гадуур явж байгаа: 2</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal
