import React, { useState } from 'react';
import AddTable from '../../Component/Home/AddTable';

const AddPage = ({ orders }) => {
    const [unit, setUnit] = useState(0);

    const addTable = () => setUnit(unit + 1); // Function to increment unit
    const removeTable = () => setUnit(unit - 1); // Function to decrement unit

    return (
        <div>
            {Array.from({ length: unit }, (_, index) => (
                <AddTable key={index} orders={orders} />
            ))}
            <button onClick={addTable}>Хүснэгт нэмэх</button>
            <button onClick={removeTable} style={{ borderColor: 'red', color: 'red' }}>Хүснэгт хасах</button>
        </div>
    );
}

export default AddPage;
