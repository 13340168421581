import React, { useEffect, useState } from 'react'
import styles from './Table/Table.module.css';
import TableHeader from '../../Utills/Table/TableHeader';
import axios from 'axios';
import dayjs from 'dayjs';

const EditTable = ({ orders, order }) => {
    const [codes, setCodes] = useState([]);
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [formData, setFormData] = useState({
        name: order.name,
        clothes: order.clothes,
        phone: order.phone,
        address: order.address,
        orderRecieve: order.orderRecieve,
        orderDay: order.orderDay,
        whoCreated: order.whoCreated,
        whoCountet: order.whoCountet,
        bankAccountPay: order.bankAccountPay,
        cash: order.cash,
        card: order.card,
        bankDescription: order.bankDescription,
        prePayment: order.prePayment,
        rent: order.rent,
        collateral: order.collateral,
        recieveClothes: order.recieveClothes,
        safe: order.safe,
        status: order.status,
        bankAccount: order.bankAccount,
        isBack: order.isBack,
        orderId: order.orderId
    });

    useEffect(() => {
        const url = 'https://clothes.templateapi.xyz';
        if (isLoading) {
            const fetchPosts = axios.get(url + '/post');
            const fetchUsers = axios.get(url + '/user');

            Promise.all([fetchPosts, fetchUsers])
                .then(([postsResponse, usersResponse]) => {
                    setCodes(postsResponse.data);
                    setUsers(usersResponse.data.data);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                });
        }
    }, [isLoading]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleClothesChange = (index, e) => {
        const { name, value } = e.target;
        const clothes = [...formData.clothes];
        clothes[index][name] = value;
        if (name === 'code') {
            const selectedCode = codes.find(code => code.title === value);
            if (selectedCode) {
                clothes[index].price = selectedCode.price;
                clothes[index].img = selectedCode.cover;
                clothes[index].handle = selectedCode.isHandle.toString();
            }
        }

        clothes[index].total = clothes[index].unit * clothes[index].price * clothes[index].day;
        setFormData(prevState => ({
            ...prevState,
            clothes
        }));
        calculateTotalRent();
    };

    const calculateCollateral = (rent) => {
        let collateralPercentage;
        if (rent >= 0 && rent < 100000) {
            collateralPercentage = 100;
        } else if (rent >= 100000 && rent < 300000) {
            collateralPercentage = 50;
        } else if (rent >= 300000 && rent < 500000) {
            collateralPercentage = 40;
        } else if (rent >= 500000 && rent < 2000000) {
            collateralPercentage = 30;
        } else if (rent >= 2000000) {
            collateralPercentage = 20;
        }

        return (rent * collateralPercentage) / 100;
    };

    const calculateTotalRent = () => {
        const totalRent = formData.clothes.reduce((sum, item) => sum + item.total, 0);
        const newCollateral = calculateCollateral(totalRent);

        setFormData(prevState => ({
            ...prevState,
            rent: totalRent,
            collateral: newCollateral
        }));
    };

    const addClothingItem = () => {
        setFormData(prevState => ({
            ...prevState,
            clothes: [...prevState.clothes, { code: '', unit: 0, price: 0, total: 0, search: '' }]
        }));
    };

    const removeClothingItem = (index) => {
        const clothes = [...formData.clothes];
        clothes.splice(index, 1);
        setFormData(prevState => ({
            ...prevState,
            clothes
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.put('https://clothes.templateapi.xyz/orders/' + order._id, formData)
            .then(() => {
                alert('Амжилттай хадгалагдлаа');
                window.location.reload(false);
            })
            .catch((e) => console.log(e));
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    };

    return (
        <div >
            <div className={styles.container}>
                {isLoading ?
                    <div className="loader-container"><div className="loader"></div></div> :
                    <div className={styles.container}>
                        <div className='row' style={{ alignItems: 'start', justifyContent: 'start' }}>
                            <div className='column'>
                                <TableHeader color={'#00FFEF'} data={
                                    dayjs().format('YYMMDD') +
                                    (parseInt(orders.filter((e) => dayjs(e.createdAt).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')).length) + 1)
                                } />
                                <TableHeader color={'#00FFEF'} data={'Нэр'} />
                                <TableHeader color={'#00FFEF'} data={'Утасны дугаар'} />
                                <TableHeader color={'#00FFEF'} data={'Хаяг'} />
                            </div>
                            <div className='column'>
                                <TableHeader color={'#00FFEF'} width={window.innerWidth > 720 ? '7vw' : '30vw'} data={''} />
                                <div className={styles.sec}>
                                    <input type="text" placeholder='name' value={formData.name} name={'name'} onChange={handleChange} id="" />
                                </div>

                                <div className={styles.sec}>
                                    <input type="text" name={'phone'} value={formData.phone} placeholder='phone' onChange={handleChange} id="" />
                                </div>

                                <div className={styles.sec}>
                                    <textarea type="text" name={'address'} value={formData.address} placeholder='address'
                                        onChange={handleChange} cols="30" rows="50" style={{ height: '50px' }} ></textarea>
                                </div>
                            </div>
                            <div className='column'>
                                {formData.clothes.map((item, index) => (
                                    <div key={index} className='row'>
                                        <div className="column">
                                            <TableHeader color={'#00FFEF'} data={'Код хайх'} width={window.innerWidth > 720 ? '4vw' : '20vw'} />
                                            <div className={styles.sec} style={{ width: window.innerWidth > 720 ? '5vw' : '20vw' }}>
                                                <input type="text" placeholder="Search code" value={!item.search ? item.code : item.search} name='search'
                                                    onChange={(e) => handleClothesChange(index, e)} style={{ width: window.innerWidth > 720 ? '4vw' : '20vw' }} />
                                            </div>
                                        </div>
                                        <div className='column'>
                                            <TableHeader color={'#00FFEF'} data={'Код'} />
                                            <div className={styles.sec} style={{ width: window.innerWidth > 720 ? '4vw' : '20vw' }}>
                                                <select name="code" value={item.code}
                                                    onChange={(e) => handleClothesChange(index, e)}>
                                                    <option value="">Сонгох</option>
                                                    {codes.filter(code => code.title === item.search).map((code) => (
                                                        <option key={code.title} value={code.title}>{code.title}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='column'>
                                            <TableHeader color={'#00FFEF'} width={window.innerWidth > 720 ? '3vw' : '22vw'} data={'Тоо'} />
                                            <div className={styles.sec} style={{ width: window.innerWidth > 720 ? '4vw' : '25vw' }}>
                                                <input type="number" name="unit" style={{ width: window.innerWidth > 720 ? '3.5vw' : '25vw' }}
                                                    value={item.unit} onChange={(e) => handleClothesChange(index, e)} />
                                            </div>
                                        </div>
                                        <div className='column'>
                                            <TableHeader color={'#00FFEF'} width={window.innerWidth > 720 ? '3vw' : '22vw'} data={'Өдөр'} />
                                            <div className={styles.sec} style={{ width: window.innerWidth > 720 ? '4vw' : '25vw' }}>
                                                <input type="number" name="day" style={{ width: window.innerWidth > 720 ? '3.5vw' : '25vw' }}
                                                    value={item.day} onChange={(e) => handleClothesChange(index, e)} />
                                            </div>
                                        </div>
                                        <div className='column'>
                                            <TableHeader color={'#00FFEF'} width={window.innerWidth > 720 ? '3vw' : '22vw'} data={'Нэгж хувцасны үнэ'} />
                                            <div className={styles.sec} style={{ width: window.innerWidth > 720 ? '4vw' : '25vw' }}>
                                                <input type="number" name="price" value={item.price} style={{ width: window.innerWidth > 720 ? '3.5vw' : '25vw' }}
                                                    onChange={(e) => handleClothesChange(index, e)} readOnly />
                                            </div>
                                        </div>
                                        <div className='column'>
                                            <TableHeader color={'#00FFEF'} width={window.innerWidth > 720 ? '4vw' : '25vw'} data={'Нийт үнэ'} />
                                            <div className={styles.sec} style={{ width: window.innerWidth > 720 ? '5vw' : '25vw' }}>
                                                <input type="number" name="total" value={item.total} readOnly style={{ width: window.innerWidth > 720 ? '4.5vw' : '25vw' }} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px', paddingTop: '15px' }}>
                                    <button type="button" onClick={addClothingItem}>Хувцас нэмэх</button>
                                    <button style={{ color: 'red', borderColor: 'red' }}
                                        type="button" onClick={() => removeClothingItem(formData.clothes.length - 1)}>Хувцас хасах</button>
                                </div>
                            </div>
                            <div className='column'>
                                <TableHeader color={'#00FFEF'} data={' '} />
                                <TableHeader color={'#00FFEF'} data={'Захиалга авсан хүн'} />
                                <TableHeader color={'#00FFEF'} data={'Захиалга авах өдөр'} />
                                <TableHeader color={'#00FFEF'} data={'Бэлдсэн хүн'} />
                                <TableHeader color={'#00FFEF'} data={'Тоолж өгсөн хүн'} />
                            </div>
                            <div className='column'>
                                <TableHeader color={'#00FFEF'} width={window.innerWidth > 720 ? '7vw' : '30vw'} data={' '} />

                                <div className={styles.sec}>
                                    <select name="orderRecieve" value={formData.orderRecieve} onChange={handleChange}>
                                        <option value="">Сонгох</option>
                                        {users.map((e) => (
                                            <option key={e.name} value={e.name}>{e.name}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className={styles.sec}>
                                    <input type="text" name={'orderDay'} value={formData.orderDay} placeholder='orderDay'
                                        onChange={handleChange} id="" />
                                </div>

                                <div className={styles.sec}>
                                    <input type="text" name={'whoCreated'} value={formData.whoCreated} placeholder='whoCreated' onChange={handleChange} id="" />
                                </div>

                                <div className={styles.sec}>
                                    <input type="text" name={'whoCountet'} value={formData.whoCountet} placeholder='whoCountet' onChange={handleChange} id="" />
                                </div>
                            </div>
                            <div className='column'>
                                <TableHeader color={'#00FFEF'} data={' '} />
                                <TableHeader color={'#00FFEF'} data={'Данс'} />
                                <TableHeader color={'#00FFEF'} data={'Бэлэн'} />
                                <TableHeader color={'#00FFEF'} data={'Карт'} />
                                <TableHeader color={'#00FFEF'} data={'Нэмэлт тайлбар'} />
                            </div>
                            <div className='column'>
                                <TableHeader color={'#00FFEF'} width={window.innerWidth > 720 ? '7vw' : '30vw'} data={' '} />

                                <div className={styles.sec}>
                                    <input type="text" name={'bankAccountPay'} value={formData.bankAccountPay} placeholder='bankAccountPay' onChange={handleChange} id="" />
                                </div>

                                <div className={styles.sec}>
                                    <input type="text" name={'cash'} value={formData.cash} placeholder='cash' onChange={handleChange} id="" />
                                </div>

                                <div className={styles.sec}>
                                    <input type="text" name={'card'} value={formData.card} placeholder='card' onChange={handleChange} id="" />
                                </div>

                                <div className={styles.sec}>
                                    <textarea type="text" name={'bankDescription'} value={formData.bankDescription} placeholder='bankDescription'
                                        onChange={handleChange} cols="30" rows="30" style={{ height: '50px' }} ></textarea>
                                </div>
                            </div>
                            <div className='column'>
                                <TableHeader color={'#00FFEF'} data={' '} />
                                <TableHeader color={'#00FFEF'} data={'Барьцаа авсан хүн'} />
                                <TableHeader color={'#00FFEF'} data={'Түрээс'} />
                                <TableHeader color={'#00FFEF'} data={'Барьцаа'} />
                                <TableHeader color={'#00FFEF'} data={'Төлөв'} />
                            </div>
                            <div className='column'>
                                <TableHeader color={'#00FFEF'} width={window.innerWidth > 720 ? '7vw' : '30vw'} data={' '} />

                                <div className={styles.sec}>
                                    <input type="text" onKeyPress={handleKeyPress} name={'prePayment'} value={formData.prePayment} placeholder='prePayment' onChange={handleChange} id="" />
                                </div>

                                <div className={styles.sec}>
                                    <input type="number" onKeyPress={handleKeyPress} name={'rent'} value={formData.rent} placeholder='rent' onChange={handleChange} id="" />
                                </div>

                                <div className={styles.sec}>
                                    <input type="number" onKeyPress={handleKeyPress} value={formData.collateral} name={'collateral'} placeholder='collateral' onChange={handleChange} id="" />
                                </div>

                                <div className={styles.sec}>
                                    <select onKeyPress={handleKeyPress} name="status" value={formData.status} onChange={handleChange}>
                                        <option value="">Сонгох</option>
                                        <option value="Захиалга">Захиалга</option>
                                        <option value="Дууссан захиалга">Дууссан захиалга</option>
                                        <option value="Дутуу авчирсан">Дутуу авчирсан</option>
                                        <option value="Авч явж байгаа">Авч явж байгаа</option>
                                        <option value="Зарсан">Зарсан</option>
                                    </select>
                                </div>
                            </div>
                            <div className='column'>
                                <TableHeader color={'#00FFEF'} data={' '} />
                                <TableHeader color={'#00FFEF'} data={'Хувцас хүлээж авсан хүн'} />
                                <TableHeader color={'#00FFEF'} data={'Дансны дугаар'} />
                                <TableHeader color={'#00FFEF'} data={'Буццалт хийгдсэн'} />
                                <TableHeader color={'#00FFEF'} data={'Хувцасны бүрэн ирсэн'} />
                            </div>

                            <div className='column'>
                                <TableHeader color={'#00FFEF'} width={window.innerWidth > 720 ? '7vw' : '30vw'} data={' '} />

                                <div className={styles.sec}>
                                    <input type="text" name={'recieveClothes'} value={formData.recieveClothes} placeholder='recieveClothes' onChange={handleChange} id="" />
                                </div>

                                <div className={styles.sec}>
                                    <input type="text" name={'bankAccount'} value={formData.bankAccount} placeholder='bankAccount' onChange={handleChange} id="" />
                                </div>

                                <div className={styles.sec}>
                                    {/* <select value={formData.isBack} name='isBack' onChange={handleChange} style={{ width: '9vw' }}>
                                        <option value="">Сонгох</option>
                                        <option value={true}>Буцаж ирсэн</option>
                                        <option value={false}>Буцаж ирээгүй</option>
                                    </select> */}
                                    {/* <input type="text" name={'isBack'} value={formData.isBack} placeholder='isBack' onChange={handleChange} id="" /> */}
                                </div>

                                <div className={styles.sec}>
                                    <select value={formData.safe} name='safe' onChange={handleChange} style={{ width: '9vw' }}>
                                        <option value="">Сонгох</option>
                                        <option value={true}>Хувцас бүрэн ирсэн</option>
                                        <option value={false}>Хувцас бүрэн ирсээгүй</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div style={{
                            display: 'flex', width: '90vw',
                            justifyContent: 'end', alignItems: 'end',
                        }}>
                            <button style={{
                                marginLeft: '30px',
                            }} onClick={handleSubmit}>Хадгалах</button>
                        </div>
                    </div>}
                <div>
                    <div className="row" style={{ gap: '20px' }}>
                        {formData.clothes.length > 0 ? formData.clothes.map((e, index) => (
                            <div key={index}>
                                <h2>{e.code.toString()}</h2>
                                <div>
                                    {e.handle ?
                                        <img src={'https://clothes.templateapi.xyz/uploads/' + e.code + '.jpg'}
                                            style={{ width: '150px', objectFit: 'cover' }}
                                        />
                                        :
                                        <img src={'https://clothes.templateapi.xyz/' + e.img}
                                            style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                        />
                                    }
                                </div>
                            </div>
                        )) : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditTable
