import axios from 'axios';
import React, { useEffect, useState } from 'react'

const SuccessOrder = ({ closeModal, data }) => {
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [formData, setFormData] = useState({
        bankAccountPay: 0,
        cash: 0,
        card: 0,
        bankDescription: '',
        recieveClothes: '',
        safe: false,
        status: '',
        bankAccount: data.bankAccount,
        note: '',
    });

    useEffect(() => {
        const url = 'https://clothes.templateapi.xyz';
        if (isLoading) {
            const fetchUsers = axios.get(url + '/user');

            Promise.all([fetchUsers])
                .then(([usersResponse]) => {
                    setUsers(usersResponse.data.data);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                });
        }
    }, [isLoading]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = () => {
        axios.put('https://clothes.templateapi.xyz/orders/' + data._id, formData)
            .then(() => window.location.reload(false))
            .catch(() => alert('Алдаа гарлаа'))
    }


    return (
        <div className='blur_container'>
            <div className="modal">
                <div className="two-line">
                    <h2>Захиалга хүлээн авах</h2>
                    <h2 onClick={closeModal} className="x">X</h2>
                </div>
                <label>
                    <p>Төлөв:</p>
                    <select name="status" onChange={handleChange}>
                        <option value="">Сонгох</option>
                        <option value="Захиалга">Захиалга</option>
                        <option value="Дууссан захиалга">Дууссан захиалга</option>
                        <option value="Дутуу авчирсан">Дутуу авчирсан</option>
                        <option value="Авч явж байгаа">Авч явж байгаа</option>
                    </select>
                </label>
                <label>
                    <p>Дансны дугаар:</p>
                    <input type="text" name="bankAccount" value={formData.bankAccount} onChange={handleChange} />
                </label>
                <label>
                    <p>Хүлээж авсан хүн:</p>
                    <select name="orderRecieve" onChange={handleChange}>
                        <option value="">Сонгох</option>
                        {users.map((e) => (
                            <option value={e.name}>{e.name}</option>
                        ))}
                    </select>
                </label>
                <label>
                    <p>Хувцасны бүрэн ирсэн:</p>
                    <select name="safe" onChange={handleChange}>
                        <option value="">Сонгох</option>
                        <option value={false}>Бүрэн ирээгүй</option>
                        <option value={true}>Бүрэн ирсэн</option>
                    </select>
                </label>
                {formData.safe === false ? <label>
                    <p>Бүрэн эрсэн эсэхийн тэмдэглэл:</p>
                    <textarea name="note" id="" onChange={handleChange} value={formData.note}
                        style={{ height: '100px', borderRadius: '8px' }} cols="30" rows="20"></textarea>
                </label> : null}
                <label>
                    <p>Төлбөрийн нэмэлт тайлбар:</p>
                    <textarea name="bankDescription" id="" onChange={handleChange} value={formData.bankDescription}
                        style={{ height: '100px', borderRadius: '8px' }} cols="30" rows="10"></textarea>
                </label>
                <label>
                    <p>Бэлэн:</p>
                    <input type="number" value={formData.cash} onChange={handleChange} name="cash" id="" />
                </label>
                <label>
                    <p>Данс:</p>
                    <input type="number" value={formData.bankAccountPay} onChange={handleChange} name="bankAccountPay" id="" />
                </label>
                <label>
                    <p>Кард:</p>
                    <input type="number" value={formData.card} onChange={handleChange} name="card" id="" />
                </label>
                <div className="two-line">
                    <button onClick={handleSubmit} style={{ color: 'green' }} className="white-button">Хадгалах</button>
                    <button style={{ color: 'red' }} onClick={closeModal} className="white-button">Буцах</button>
                </div>
            </div>
        </div>
    )
}

export default SuccessOrder
