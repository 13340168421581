import React, { useState } from 'react'
import styles from './Navbar.module.css'
import AddImployeModal from '../../Utills/Modal/Imploye/AddImployeModal';
import BackList from '../../Utills/Modal/BackList/BackList';
import { Link } from 'react-router-dom';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { FaRegEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";

const Navbar = ({ orders }) => {
    const [modal, setModal] = useState(false);
    const [hideIncome, setHideIncome] = useState(true);
    const [backModal, setBackModal] = useState(false);
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());
    const [startDateEnable, setStartDateEnable] = useState(false);
    const [endDateEnable, setEndDateEnable] = useState(false);

    const closeModal = () => {
        setModal(false);
        setBackModal(false);
    }

    const totalIncome = orders.filter((e) => {
        return (dayjs(e.createdAt).isSame(startDate, 'date') || dayjs(e.createdAt).isAfter(startDate))
            && (dayjs(e.createdAt).isSame(endDate, 'date') || dayjs(e.createdAt).isBefore(endDate))
    })
        .reduce((a, b) => a + b.clothes.filter((e) => e.total > 0)
            .reduce((empty, clothes) => empty + parseFloat(clothes.total), 0), 0)

    const fivePercentIncome = (totalIncome * 0.05).toFixed(2);

    return (
        <>
            {backModal ? <BackList orders={orders} closeModal={closeModal} /> : null}
            {modal ? <AddImployeModal closeModal={closeModal} /> : null}
            <div className={styles.container}>
                <p>Нийт орлого:
                    {hideIncome ? '******' : Intl.NumberFormat().format(totalIncome)}
                </p>
                <p onClick={() => setHideIncome(!hideIncome)}>{hideIncome ? <FaRegEye size={30} color='green' /> :
                    <FaRegEyeSlash size={30} color='red' />}</p>
                <p>Орлогын хувь: {Intl.NumberFormat().format(fivePercentIncome)}</p>
                <label>
                    Эхлэх хугацаа:
                    {startDateEnable ? <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                            <DemoItem label="Controlled calendar">
                                <div className='date' >
                                    <h2 onClick={() => setStartDateEnable(false)}>X</h2>
                                    <DateCalendar value={startDate} onChange={(newValue) => setStartDate(newValue)} />
                                </div>
                            </DemoItem>
                        </DemoContainer>
                    </LocalizationProvider> :
                        <div onClick={() => setStartDateEnable(true)}
                            style={{ border: '1px solid #d3d3d3', padding: '10px 20px', borderRadius: '10px', cursor: 'pointer' }}>
                            {dayjs().format('YYYY-MM-DD')}
                        </div>
                    }
                </label>
                <label>
                    Дуусах хугацаа:
                    {endDateEnable ? <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                            <DemoItem label="Controlled calendar">
                                <div className='date' >
                                    <h2 onClick={() => setEndDateEnable(false)}>X</h2>
                                    <DateCalendar value={endDate} onChange={(newValue) => setEndDate(newValue)} />
                                </div>
                            </DemoItem>
                        </DemoContainer>
                    </LocalizationProvider> :
                        <div onClick={() => setEndDateEnable(true)}
                            style={{ border: '1px solid #d3d3d3', padding: '10px 20px', borderRadius: '10px', cursor: 'pointer' }}>
                            {dayjs().format('YYYY-MM-DD')}
                        </div>
                    }
                </label>
                <Link style={{
                    textDecoration: 'none',
                    border: '1px solid blue',
                    padding: '10px 20px'
                }} to={'/add'}>Олон захиалгын үүсгэх</Link>
                <Link style={{
                    textDecoration: 'none',
                    border: '1px solid blue',
                    padding: '10px 20px'
                }} to={'/'}>Нүүр хуудас</Link>
                <button onClick={() => setBackModal(true)}>Барьцаа буцаан олгох жагсаалт</button>
                <button onClick={() => setModal(true)}>Ажилтан бүртгэх систем</button>
                {/* он сараар орлого болон 5% ийг харуулах */}
                {/* <p>Захиалгын тоо, орлого, орлогын хувь, хувцасаа авчирч, өгөөгүй хүмүүс</p> */}
                {/* <p>Данс бэлэн карт хэд орсон нь харагдана</p> */}
            </div>
        </>
    )
}

export default Navbar
