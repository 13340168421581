import axios from 'axios';
import React, { useState } from 'react'

const AddImployeModal = ({ closeModal }) => {
    const [name, setName] = useState('');
    const [register, setRegister] = useState('');
    const [facebook, setFb] = useState('');
    const [phone, setPhone] = useState('');
    const [subLoader, setSubLoader] = useState(false);

    const submitAddImploye = () => {
        setSubLoader(true);
        axios.post('https://clothes.templateapi.xyz/user/register', {
            name: name,
            email: name + '@gmail.com',
            password: '1234',
            phone: phone,
            fb: facebook,
            register: register
        })
            .then((e) => {
                window.location.reload(false);
            })
            .catch((e) => {
                console.log(e);
                setSubLoader(false);
            })
    }

    return (
        <div className='blur_container'>
            {subLoader ?
                <div className='loader-container'><div className="loader"></div></div>
                : <div className="modal">
                    <div className="two-line">
                        <h2>Ажилтан нэмэх</h2>
                        <h2 className='x' onClick={closeModal}>X</h2>
                    </div>
                    <label className='label'>
                        Нэр:
                        <input type="text" name="name" value={name}
                            onChange={(e) => setName(e.target.value)} placeholder='Нэр' />
                    </label>
                    <label className='label'>
                        Ригестэр дугаар:
                        <input type="text" name="name"
                            onChange={(e) => setRegister(e.target.value)} placeholder='Нэр' />
                    </label>
                    <label className='label'>
                        Утас:
                        <input type="text" name="name"
                            onChange={(e) => setPhone(e.target.value)} placeholder='Нэр' />
                    </label>
                    <label className='label'>
                        Facebook хаяг:
                        <input type="text" name="name"
                            onChange={(e) => setFb(e.target.value)} placeholder='Нэр' />
                    </label>
                    <div className="two-line">
                        <button onClick={submitAddImploye}>Хадгалах</button>
                        <button onClick={closeModal} style={{ borderColor: 'red', color: 'red' }}>Буцах</button>
                    </div>
                </div>}
        </div>
    )
}

export default AddImployeModal
