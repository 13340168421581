import React, { useState } from 'react'
import styles from './BackList.module.css';
import TableHeader from '../../Table/TableHeader';
import TableSection from '../../Table/TableSection';
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import SuccessModal from '../SuccessModal';
import axios from 'axios';

const BackList = ({ closeModal, orders }) => {
    const [succModal, setSuccModal] = useState(false);
    const [order, setOrder] = useState({})

    const hide = () => {
        setSuccModal(false);
    }

    const handleSubmit = (e) => {
        // e.preventDefault();
        axios.put('https://clothes.templateapi.xyz/orders/' + e._id, {
            isback: true,
        })
            .then(() => {
                window.location.reload(false);
            })
            .catch((e) => console.log(e));
    };

    return (
        <>
            <div className='blur_container'>
                <div className="modal" style={{
                    maxHeight: '100vh',
                    // overflow: 'auto',
                }}>
                    {/* {succModal ? < SuccessModal closeModal={hide} data={order} /> : null} */}
                    <div className="two-line">
                        <h2>Буцаалт хийгдэх жагсаалт</h2>
                        <h2 onClick={closeModal} className='x'>X</h2>
                    </div>
                    {orders.filter((e) => e.isback === false)
                        .filter((e) => e.status === 'Дууссан захиалга')
                        .map((e) => (
                            <div className={window.innerWidth > 720 ? 'row' : 'column'}>
                                <div className="column">
                                    <TableHeader data={'Код'} width={window.innerWidth > 720 ? '7.6vw' : '50vw'} />
                                    <TableSection data={e.orderId} />
                                </div>
                                <div className="column">
                                    <TableHeader data={'Нэр'} width={window.innerWidth > 720 ? '7.6vw' : '50vw'} />
                                    <TableSection data={e.name} />
                                </div>
                                <div className="column">
                                    <TableHeader data={'Дансны дугаар'} width={window.innerWidth > 720 ? '7.6vw' : '50vw'} />
                                    <TableSection data={e.bankAccount} />
                                </div>
                                <div className="column">
                                    <TableHeader data={'Барьцаа'} width={window.innerWidth > 720 ? '7.6vw' : '50vw'} />
                                    <TableSection data={e.collateral} />
                                </div>
                                <div className="column">
                                    <TableHeader data={'Үйлдэл'} width={window.innerWidth > 720 ? '7.6vw' : '50vw'} />
                                    <div style={{
                                        border: '1px solid #000', width: window.innerWidth ? '9vw' : '20vw', height: '6.2vh',
                                        display: 'flex', alignItems: 'center', justifyContent: 'center'
                                    }}>
                                        <IoIosCheckmarkCircleOutline onClick={() => {
                                            // setSuccModal(true);
                                            handleSubmit(e);
                                            setOrder(e)

                                        }} size={35} color='green' className='hover' />
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </>
    )
}

export default BackList
