import React, { useState } from 'react'
import styles from './Table.module.css';
import TableSection from '../../../Utills/Table/TableSection';
import TableHeader from '../../../Utills/Table/TableHeader';
import TableWholeColumn from '../../../Utills/Table/TableWholeColumn';
import SuccessModal from '../../../Utills/Modal/SuccessModal';
import SuccessOrder from '../../../Utills/Modal/Order/SuccessOrder';

const Table = ({ e, setId }) => {
    const [modal, setModal] = useState(false);
    const [data, setData] = useState({});

    const closeModal = () => {
        setModal(false);
    }

    const totalIncome = e.clothes.reduce((acc, item) => acc + parseFloat(item.total), 0);

    const fivePercentIncome = (totalIncome * 0.05).toFixed(2);

    return (
        <div className={styles.container}>
            {modal ? <SuccessOrder closeModal={closeModal} data={data} /> : null}
            {/* <div className='row'> */}
            {/* {datas.map((e) => ( */}
            <div className='row' style={{ alignItems: 'start', justifyContent: 'start' }}>
                <div className="column">
                    <TableWholeColumn data={e.orderId} status={e.status} />
                </div>
                <div className='column'>
                    <TableHeader data={' '} />
                    <TableHeader data={'Нэр'} />
                    <TableHeader data={'Утасны дугаар'} />
                    <TableHeader data={'Хаяг'} />
                </div>
                <div className='column'>
                    <TableHeader width={'7.5vw'} data={''} />
                    <TableSection data={e.name} />
                    <TableSection data={e.phone} />
                    <TableSection data={e.address} />
                </div>
                <div className='column'>
                    <TableHeader width={'4vw'} data={'Код'} />
                    {e.clothes.map((el) => (
                        <TableSection width={'5vw'} data={el.code} />
                    ))}
                </div>
                <div className='column'>
                    <TableHeader width={'4vw'} data={'Тоо'} />
                    {e.clothes.map((el) => (
                        <TableSection width={'5vw'} data={el.unit} />
                    ))}
                </div>
                <div className='column'>
                    <TableHeader width={'4vw'} data={'Хувцасны үнэ'} />
                    {e.clothes.map((el) => (
                        <TableSection width={'5vw'} data={el.total} />
                    ))}
                </div>
                <div className='column'>
                    <TableHeader data={' '} />
                    <TableHeader data={'Захиалга авсан хүн'} />
                    <TableHeader data={'Захиалга авах өдөр'} />
                    <TableHeader data={'Бэлдсэн хүн'} />
                    <TableHeader data={'Тоолж өгсөн хүн'} />
                </div>
                <div className='column'>
                    <TableHeader width={'7.5vw'} data={' '} />
                    <TableSection data={e.orderRecieve} />
                    <TableSection data={e.orderDay} />
                    <TableSection data={e.whoCreated} />
                    <TableSection data={e.whoCountet} />
                </div>
                <div className='column'>
                    <TableHeader data={' '} />
                    <TableHeader data={'Данс'} />
                    <TableHeader data={'Бэлэн'} />
                    <TableHeader data={'Карт'} />
                    <TableHeader data={'Нэмэлт тайлбар'} />
                </div>
                <div className='column'>
                    <TableHeader width={'7.5vw'} data={' '} />
                    <TableSection data={e.bankAccountPay} />
                    <TableSection data={e.cash} />
                    <TableSection data={e.card} />
                    <TableSection data={e.bankDescription} />
                </div>
                <div className='column'>
                    <TableHeader data={' '} />
                    <TableHeader data={'Барьцаа авсан хүн'} />
                    <TableHeader data={'Түрээс'} />
                    <TableHeader data={'Барьцаа'} />
                    <TableHeader data={'Төлөв'} />
                </div>
                <div className='column'>
                    <TableHeader width={'7.5vw'} data={' '} />
                    <TableSection data={e.prePayment} />
                    <TableSection data={e.rent} />
                    <TableSection data={e.collateral} />
                    <TableSection data={e.status} />
                </div>
                <div className='column'>
                    <TableHeader data={' '} />
                    <TableHeader data={'Хувцасны бүрэн ирсэн'} />
                    <TableHeader data={'Хувцас хүлээж авсан'} />
                    <TableHeader data={'Дансны дугаар'} />
                    <TableHeader data={'Буццалт хийгдсэн'} />
                </div>
                <div className='column'>
                    {/* <TableHeader width={'7.5vw'} data={' '} /> */}
                    <button style={{
                        height: '6vh',
                        borderRadius: '0',
                        width: '9vw',
                        fontSize: '12px'
                    }}
                        onClick={() => {
                            // setModal(true);
                            setId(e._id);
                        }}
                    >
                        Захиалга өөрчлөх
                    </button>
                    <TableSection data={e.safe ? "Хувцас бүрэн ирсэн" : "Хувцас бүрэн ирсээгүй"} />
                    <TableSection data={e.recieveClothes} />
                    <TableSection data={e.bankAccount} />
                    <TableSection data={e.isback ? 'Буцаагдсан' : 'Буцаагдаагүй'}
                        bg={e.isback ? 'green' : 'red'} color={'white'} />
                </div>
            </div>
            <div className='row' style={{
                gap: '30px',
                backgroundColor: 'yellow',
                padding: '10px',
            }}>
                <p>
                    Орлогын 5%: {fivePercentIncome}
                </p>
                <p>
                    Нийт орлого: {totalIncome}
                </p>
            </div>
            {/* ))} */}
            {/* </div> */}
        </div>
    )
}

export default Table
