import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home/Home';
import Navbar from './Component/Navbar/Navbar';
import { useEffect, useState } from 'react';
import axios from 'axios';
import AddPage from './Pages/AddPage/AddPage';
function App() {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isLoading) {
      axios.get('https://clothes.templateapi.xyz/orders?sort=-createdAt')
        .then((e) => {
          setOrders(e.data.data);
          setIsLoading(false);
        })
        .catch((e) => console.log(e));
    }
  }, [isLoading]);

  if (isLoading) {
    return <div className='loader-container'><div className="loader"></div></div>
  }

  return (
    <>
      <Router>
        {window.innerWidth > 720 ? <Navbar orders={orders} /> : <></>}
        <Routes>
          <Route path='/' element={<Home orders={orders} />} />
          <Route path='/add' element={<AddPage orders={orders} />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
