import React, { useState } from 'react'
import styles from './TableSection.module.css'

const TableSection = ({ data, width, color, bg }) => {
    return (
        <div className={styles.container}>
            <div className={styles.sec} style={{ width: width ? width : '', background: bg ? bg : '', color: color ? color : '' }}>
                {data}
            </div>
        </div>
    )
}

export default TableSection
