import React, { useState } from 'react'
import styles from './TableSection.module.css'

const TableWholeColumn = ({ data, status }) => {
    return (
        <div className={styles.tholecontainer}>
            <div className={styles.wholesec} style={{
                backgroundColor: status === 'Захиалга' ? 'red' :
                    status === 'Дууссан захиалга' ? 'green' : status === 'Дутуу авчирсан' ? 'orange' : 'yellow',
                color: status === 'Авч явж байгаа' ? '#333' : '#fff',
                fontSize: '15px'
            }}>
                {data}
            </div>
        </div>
    )
}

export default TableWholeColumn
